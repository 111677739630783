// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-of-directors-index-js": () => import("./../../../src/pages/board-of-directors/index.js" /* webpackChunkName: "component---src-pages-board-of-directors-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-welcome-home-at-sugar-land-memorial-park-index-js": () => import("./../../../src/pages/welcome-home-at-sugar-land-memorial-park/index.js" /* webpackChunkName: "component---src-pages-welcome-home-at-sugar-land-memorial-park-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-opengraph-project-js": () => import("./../../../src/templates/opengraph-project.js" /* webpackChunkName: "component---src-templates-opengraph-project-js" */)
}

